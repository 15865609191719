.navbar {

    padding: 10px;
}

.nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.text-contact-headers{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #464C51;
}

.itemsnavbar:hover {
    border-left: 3px solid var(--orangeBon, #E69534);
    background: var(--foundation-light-hover, #FFEFD9);
    padding: 10px 35px;
    color: var(--Blue-800, #E69534);

}



.itemsnavbar {
    color: var(--Blue-800, #464C51);

    padding: 5px 25px;
}

.itemsnavbartitlte {
    color: var(--Blue-800, #464C51);
    font-weight: 800;
    padding: 5px 5px;

    
}

.itemsnavbarSOustitlte{
    padding: 5px 15px;

}


.navbartitle {
    background-color: white;
    display: inline-flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.navbartitle:hover {
    background-color: #E69534;
    color: white;
}

.activeitem {
    background-color: white !important;
    display: inline-flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #008774 !important;
    font-weight: 500;
}

.nav-item {
    color: orange;
    cursor: pointer;
    position: relative;
    font-weight: 500;

}

.nav-item.active {
    color: green;
}

.subnav-list {
    display: none;
    position: absolute; 
    width: 300px;
    top: 100%;
    left: 0;
    background-color: white;
    z-index: 1001;
    padding: 10px;
    box-shadow: 0px 4px 25px 0px rgba(0, 169, 145, 0.13);
}

.nav-item:hover .subnav-list {
    display: block;
}

.subnav-list.active {
    display: block;
}

.PasAlaligne {
    white-space: nowrap;
}

.navbar {
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-toggle {
    display: none;
    cursor: pointer;
}

.blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  

.menu-toggle i {
    font-size: 1.5rem;
}

.nav-list {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    z-index: 1000;
}

.nav-item {
    margin-right: 1rem;
    cursor: pointer;
}

.nav-item:hover {
    text-decoration: underline;
}

.menu-toggle button{
    color: #E69534; 
}

.AfficherDiverder{
    display: none;
}

/* Styles pour les petits écrans */
@media screen and (max-width: 1024px) {
    .menu-toggle {
        display: block;

    }

    .AfficherDiverder{
        display: block;
    }


    .nav-list {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
       
        align-items: center;
        transform: translateX(-80%);
        width: 400px; 
        background-color: rgba(255, 255, 255, 0.95); 
        padding: 1rem 0;
        margin-top: 0.5rem;
        text-align: center;
        border-radius: 12px ;
        
        -webkit-box-shadow: -7px 1px 20px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: -7px 1px 20px -4px rgba(0,0,0,0.75);
        box-shadow: -7px 1px 20px -4px rgba(0,0,0,0.75);


       
    }

    .nav-list.open {
        display: flex;
    }

    .nav-item {
        margin: 0;
        padding: 0.5rem 1rem;
        width: 100%;
        text-align: center;
    }

    .nav-item:hover {
        background-color: #E69534;
    }
}
