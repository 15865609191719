

.react-chatbot-kit-chat-header{
  
    border-radius: 16.533px 16.533px 0px 0px;
  background: var(--degra-vert, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));
  box-shadow: 0px 19.84px 28.107px 0px rgba(174, 10, 10, 0.45);
  
  }
  .react-chatbot-kit-chat-bot-message-arrow{
    width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid #008774 !important;
      position: absolute;
      left: -7px;
      top: 13px;
  }
  
  .react-chatbot-kit-chat-container{
    width: 300px !important;
  }
  
  .react-chatbot-kit-chat-btn-send {
    background-color: #008774 !important;
    width: 100px;
    border: none;
    color: #fff;
    border-bottom-right-radius: 5px;
  }
  
  .react-chatbot-kit-chat-bot-message {
    border-radius: 9.92px 9.92px 9.92px 0px;
    background: var(--foundation-green-normal-active, #008774) !important;
    box-shadow: 0px 1.653px 0.827px 0px rgba(0, 0, 0, 0.05);
  }
  
  .chatboxheader{
    display: flex;
    flex-direction: row;
    color: white;
    gap: 10px;
  }
  .onlineText{
    color: #43EE7D  ;
    font-weight: 300;
  }
  
  .chatbotTitle{
    font-size: 20px !important;
  }

  .app-chatbot-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 15px;
    color: #fff;
    background: var(--degra-vert, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));
    border: none;
    position: fixed;
    bottom: 50px;
    z-index: 9999;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.6s ease-in-out;
  }
  .app-chatbot-button:hover {
    transform: scale(1.1);
  }


  .react-chatbot-kit-chat-container {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 40px;
    bottom: 80px;
    z-index: 9999;
    box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
    border-radius: 5px;
  }

  .optionsQuestion-button{
    font-size: 12px;
    font-weight: bold;
    border-radius: 12px;
    box-shadow: 0px -3.307px 13.227px 0px rgba(0, 0, 0, 0.08);
  }

  .react-chatbot-kit-chat-input-form{
    display: none;
  }
  