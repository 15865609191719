.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.containerSociaux {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px; /* Espace pour les logos de réseaux sociaux */
  position: relative;
}
/* Styles pour les logos de réseaux sociaux */
.social-iconsSociaux {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  bottom: 55px;
  left: 0;
  padding: 15px 15px 15px 0px;
  background-color: #fff; /* Fond blanc pour les logos */
  border-radius: 0px 12px 12px 0px;
  z-index: 9999; /* Assure que les logos soient toujours au-dessus des autres éléments */
  -webkit-box-shadow: 32px 6px 23px -9px rgba(0,0,0,0.4);
  -moz-box-shadow: 32px 6px 23px -9px rgba(0,0,0,0.4);
  box-shadow: 32px 6px 23px -9px rgba(0,0,0,0.4);
}

.elemntsociaux:hover{
  -webkit-box-shadow: 27px 26px 23px -18px rgba(0,0,0,0.91);
  -moz-box-shadow: 27px 26px 23px -18px rgba(0,0,0,0.91);
  box-shadow: 27px 26px 23px -18px rgba(0,0,0,0.91);

}

.social-iconsSociaux a:hover {
  display: inline-block;
  margin-left: 10px;
}


.social-iconsSociaux a {
  display: inline-block;
  margin-left: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.p-password-input{
  width: 100% !important;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td{
border: 2px solid rgb(236, 236, 236) !important;
}

  
.ConnexionImgae {
  height: 100vh !important;

  border-radius: 0% 15% 15% 0%;
}


.hauteurTotal{
  height: 100vh !important
}

.largeurTotal{
  width: 100vh !important;
}


.btnPrimary {
  border-radius: 5px !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  background: var(--gradiant-green-orange, linear-gradient(90deg, #36805B 28.05%, #F28A00 100%)) !important;
  box-shadow: 0px 16px 32px 0px #009ee21a !important;

  
}

.btnSecondary {
  border-radius: 4.986px !important;
background: var(--Style, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%)) !important;
}

.btnThree {
  display: inline-flex !important;
padding: 10.021px 21.292px 9.979px 22.375px !important;
justify-content: center !important;
align-items: center !important;
}

.tailleIcons {
  width: 22px !important;
  
  flex-shrink: 0 !important;
}

.MarginNavbarSection {
  margin-right: 24rem !important;
}

.largeurNavLateral {
margin-right: 1rem !important; 
}


.main-container {
  display: flex;
  flex-direction: row;
  height: 100vh; /* Hauteur totale de la page */
}

.navbar {
  flex: 0 0 350px !important; /* Largeur fixe de la navbar */
  
  height: 100%;
}

.content {
  flex: 1 1 auto !important;
  width: 100vh; /* Prend tout l'espace restant */
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number{
  background: var(--gradiant-01, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));;
}

.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator{
  background: #6fc79b;


}

.p-selectbutton .p-button.p-highlight {
  background: #007F6D;
  border-color: #007F6D;
}


.OptionPourEntreprise{
  width: 100% !important;
}


.degradiantGreen {
  background: var(--gradiant-01, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));
  box-shadow: 0px 0px 27px 0px #9EDEB4;
}

.barColorHistogramme{
  background: var(--gradiant-01, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));

}


.DisplayALignItems{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.text-colors-perso{
  color: var(--noir, #000);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  }


  .btnDegrad{
    border-radius: 7.708px;
    background: var(--gradiant-green-orange, linear-gradient(90deg, #36805B 28.05%, #F28A00 100%)) !important;
    border: none;
    color: white;
    font-weight: bold;
    padding: 16px 0px;
  }
  
  .activeNavLInk{
    border-radius: 4.986px !important;
    background: var(--Style, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%)) !important;
  
    padding: 16px;
    font-weight: bold;
    color: white;
  }

  .btnBacground01{
    border-radius: 50px;
    background: var(--gradiant-green-orange, linear-gradient(90deg, #36805B 28.05%, #F28A00 100%));
    padding: 10px 25px;
    color: white;
    font-weight: 500;
  }
  
  .btnBacground03{
    border-radius: 50px;
    background: var(--gradiant-green-orange, linear-gradient(90deg, #eb675e 28.05%, #f20000 100%));
    padding: 10px 25px;
    color: white;
    font-weight: 500;
  }
  
  .btnBacground02{
    border-radius: 50px;
    border: 1px solid var(--Foundation-Green-Dark, #007F6D);
    padding: 10px 25px;
    color: #007F6D;
    font-weight: 500;
  }

  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none !important;
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #06b6d4;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #e5e7eb;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #e5e7eb transparent;
    background: #ffffff;
    color: #6b7280;
    padding: 1.25rem;
    font-weight: 700;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}

.sectionAnime{
  opacity: 0; /* Commencez avec une opacité de 0 */
  transform: translateY(40px); /* Déplacez l'élément vers le bas de 20 pixels */
  animation: fadeInUp 0.7s ease forwards; /* Utilisez l'animation fadeInUp */
}

.sectionAnime02{
  opacity: 0; /* Commencez avec une opacité de 0 */
  transform: translateY(40px); /* Déplacez l'élément vers le bas de 20 pixels */
  animation: fadeInUp 1.1s ease forwards; /* Utilisez l'animation fadeInUp */
}
.sectionAnime03{
  opacity: 0; /* Commencez avec une opacité de 0 */
  transform: translateY(40px); /* Déplacez l'élément vers le bas de 20 pixels */
  animation: fadeInUp 1.5s ease forwards; /* Utilisez l'animation fadeInUp */
}
.sectionAnime04{
  opacity: 0; /* Commencez avec une opacité de 0 */
  transform: translateY(40px); /* Déplacez l'élément vers le bas de 20 pixels */
  animation: fadeInUp 1.9s ease forwards; /* Utilisez l'animation fadeInUp */
}

.element {
  opacity: 0; /* Commencez avec une opacité de 0 */
  transform: translateY(40px); /* Déplacez l'élément vers le bas de 20 pixels */
  animation: fadeInUp 2.1s ease forwards; /* Utilisez l'animation fadeInUp */
}

/* Animation fadeInUp */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
 
  z-index: 1000 !important; /* Valeur de z-index élevée pour être au-dessus de tous les autres éléments */
}



p-dialog p-component p-dialog-default p-ripple-disabled p-dialog-enter-done{
  z-index: 99999 !important
}


.zindexpossiont{
  z-index: 99999; 
}

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #F28A00;
    color: #F28A00 !important;}

  .inputFormulaire{
    display: flex;
    flex-direction: column !important;
  }
  
  .divFormRow{
    width: 100% !important;
    background-color: red;
  }
  .brackgrounndgreanDegradeoff{
    background: var(--gradiant-01, linear-gradient(90deg, #34a12a 11.71%, #5FE58E 100%));

  }
  
  
  .ImagesUserDetail{
    width: 142px;
    height: 141px;
    border-radius: 12px;
  
    flex-shrink: 0;
  }

  .pdf-div{
    padding: 10%;
    background-color: #282c34;
    margin-top: 50px;
  }

  .react-pdf__Page__canvas{
    margin-top: 25px;
    width: 100% !important;
    height: 100% !important;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  a {
    text-decoration: none !important;
}

.ql-editor {
  box-sizing: border-box !important;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.displayLIgneColForce{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.AfficheElement{
  display: none;
}


/* Responsive design */


@media only screen and (min-width: 320px) and (max-width: 767px) {
  /* For tablets: */
  .textBienvenue {
    font-size: 18px !important
  }

  .AfficheElement{
    display: block;
    padding-bottom: 10px;
  }
  .cacheElement{
    display: none !important
  }

  .afficherENligne{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  
  }

  .tailleLOgo{
    height: 50px !important
  }


  .paddindModif{
    padding-right: 25px
  }
  
  .pousserHaut{
    margin-top: 20px
  }

  .pourserENBas10{
    margin-bottom: 10px
  }

  .text-carrousel{
    font-size: 12px !important
  }

  .tailleTitrePratiques{
    font-size: 20px !important
  }

  .tailleTitrePratiques18{
    font-size: 18px !important
    
  }
  
}


.AfficherContact {
  display: none;
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .texteTitreBienvenue{
    font-size: 18px;
    font-weight: 800;
    
  }

  .AfficherContact {
    display: block !important;
  }
  

  .AfficheElement{
    display: block;
    padding-bottom: 10px;
  }
  .cacheElement{
    display: none !important
  }

  .afficherENligne{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  
  }

  .tailleLOgo{
    height: 75px !important
  }

  .paddindModif{
    padding-right: 50px
  }

  .pourserENBas10Tablette{
    margin-bottom: 15px
  }

  .parentImage{
    width: 250px !important;
  }

  .text-contact{
    padding-top: 12px;
    font-size: 12px !important;
    font-weight: 600;
  }


 

}

.aligneiconetTexte{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center;
}









  
  @import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
