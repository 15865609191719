
@keyframes scroll {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(calc(-250px * 7));
    }
}


.slider{
    height: 120px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;
}

.slider .slide-track{
    animation: scroll 20s linear infinite;
    display: flex;
    flex-direction: row;
    width: calc((250px *14));
}

.slider .slide{
    height: 120px;
    width: 500px;
}