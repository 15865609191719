.flash-info {
    height: 64px;
    
    color: white;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  
  .marquee {
    width: 100%;

    height: 100%;
    white-space: nowrap;
    overflow-x: none;
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 15s linear infinite;
  }

  .backdefi{
    background-color: #008774;
  }
  
  .news-item {
    display: inline-block;
    
    font-size: 16px;
    color: white;
  }
  
  .news-item.active {
    background-color: #008774;
  }
  
  .flash-info-label {
    position: absolute;
    top: 50%;
    left: 10px;
    height: 75px;
    background: var(--orangeBon, #E69534);
    transform: translateY(-50%);
    color: #fff;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    z-index: 1;
    border-radius: 12px;
  }
  
  @keyframes marquee {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(calc(-250px * 7));
    }
  }


  .conteneur-textes {
    width: 100%; /* Largeur du conteneur */
    overflow: hidden; /* Masquer le texte qui dépasse */
    display: flex; /* Activer la disposition flexible */
  }
  
  .texte {
    flex: 1; /* Prendre toute la largeur disponible */
    padding: 10px;
    white-space: nowrap; /* Empêcher le retour à la ligne */
    animation: defillement 40s linear infinite; /* Animer le défilement */
  }
  
  @keyframes defillement {
    0% {
      transform: translateX(40%); /* Démarrer le texte à droite */
    }
    100% {
      transform: translateX(-100%); /* Déplacer le texte vers la gauche */
    }
  }
  









  