body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.positionheaders{
  
    position:absolute;
    z-index: 99 ;
   
  
  

}

.parentImage{
  position: relative;
  width: 350px  !important;
  height: 525px !important;
  background: var(--degra-vert, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));
}

.VoirPlusPresident{
  background: var(--degra-vert, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%)) !important;
  border-radius: 12px !important;
}

.ImgaePresident{
  width: 550px;
height: 400px;
flex-shrink: 0;
object-fit: cover;
border-radius: 12px;
position: absolute;
  top: 25px;

  right: -25px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

.btn-action-green{
  display: flex;
  color: white;
  font-weight: 500;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
background: var(--degra-vert, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));
}
.btn-action-greenSaisine{
 
  background: var(--degra-vert, linear-gradient(90deg, #36805B 11.71%, #5FE58E 100%));
  display: inline-flex;
  padding: 10px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
  font-weight: 500;
  color: white;
}
.btn-action-greenSaisine:hover{
  background: #004C41;
}

.pxSaisine {
  padding: 15% 10%;
}
.btn-action-OrangeSaisine{
  display: inline-flex;
  padding: 10px 11px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
  font-weight: 500;
  background: var(--degrade, linear-gradient(263deg, #F9BD6A 4.26%, #FF9502 94.25%));
}
.btn-action-OrangeSaisine:hover{
  background: #d68a1f;
  color: antiquewhite;
}
.borderradiusCarousel{
  border-radius: 25px !important;
}

.missionshadow{
  box-shadow: 0px 0px 37px -2px rgba(17, 24, 39, 0.05);
}

.borderArrondie{
  border-radius: 50% !important;
}

.btn-action-BlackSaisine{
  background: var(--Blue-800, #464C51);
  display: inline-flex;
  padding: 10px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
  font-weight: 500;
  color: white;
  }

  .btn-action-BlackSaisine:hover{
    background: #62686d;
  }

  .Content-text{

  }

  .ImageContentTest{
    float: left;
    margin-right: 20px;
  }


  .contenuCarousel{
    height: 10% !important;
  }

  header{
    position:relative ;
    z-index: 900 !important;
}
 
menu{
    position:relative;
    z-index: 1000 !important;
}


.marginContinaierWeb{
  margin: 0% 8% ;
}

.backgroundfooter{
  background: var(--foundation-green-dark-active, #004C41) !important;
}

.CommissionTexte{
  color: var(--orangeBon, #E69534);

  /* headers 05 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.Bienvenue{
  color: var(--foundation-green-normal-active, #008774);
text-align: center;

/* headers 05 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.mcontenuCarousel{
  height: 100px !important;
}
.contourenbas{
  border-bottom: 1px dashed #969696 ;
}

.flex-center-item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.positionAvatar{
  z-index: 99999 !important;
}
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-solid-rounded/css/uicons-solid-rounded.css');


@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}